$(window).on('load', function () {
    openG();
    descG();
    navG();
});

function openG() {

    // Open popup gallery and do some actions
    $('.article-gallery').on('click',function (e) {
        e.preventDefault();

        var data = $(this).attr('data-gallery-images'),
            gallery = $('#images_' + data);  

        $('body').addClass('overflow-hidden');
        gallery.toggleClass('d-none');

        // Start gallery from first image
        count = 1;
        gallery.find('.gallery-list li:first').addClass('selected');
        gallery.find('.gallery-list li:first .nav-prev').addClass('d-none');
        gallery.find('.gallery-list li:last .nav-next').addClass('d-none');

        
    closeG(gallery);

    });

}

function descG() {

    // Show and hide image description
    $('.image-desc-open').on('click', function () {
        $('.image-desc-open').toggleClass('open');

        $('.gallery-image-desc').slideToggle("slow", function () {            
            $('.gallery-image-desc').toggleClass('d-none');
        });

    });

}

function navG() {
    const images = $('.gallery-list .gallery-item').length,
        prevBtn = $('.nav-prev'),
        nextBtn = $('.nav-next');
    var currImg = $('.gallery-list li:first');

    prevBtn.on('click', prev);
    nextBtn.on('click', next);

    function prev() {
        var currImg = $('.gallery-list .selected');
        currImg.removeClass('selected');
        currImg.prev().addClass('selected');
        currImg = $('.gallery-item.selected');
        count--;
        if (count < 1) {
            $('.gallery-item.selected .nav-prev').addClass('d-none');
        }

        if (!$('.gallery-item.selected .gallery-image-desc').html()) {
            $('.gallery-item.selected .image-desc-open').addClass('d-none');
        };

    }

    function next() {
        var currImg = $('.gallery-list .selected');
        currImg.removeClass('selected');
        currImg.next().addClass('selected');
        currImg = $('.gallery-item.selected');
        count++;
        if (count > images) {
            $('.gallery-item.selected .nav-next').addClass('d-none');
        }
        // Hide open arrow if image description is empty 
        if (!$('.gallery-item.selected .gallery-image-desc').html()) {
            $('.gallery-item.selected .image-desc-open').addClass('d-none');
        };

    };

}

function closeG(gallery) {
    // var gallery = $('#images_'+ data);
    $('.gallery-close').click(function (e) {
        e.preventDefault();

        $('body').removeClass('overflow-hidden');
        gallery.addClass('d-none');

        // Unselect image
        $('.gallery-item.selected').removeClass('selected');
        count = 0;

    });

}
