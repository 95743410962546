$(function ($) {
    const BASE_URL = window.location.origin + '/';
    const pollButton = $(".poll_vote");
    const answers = $("input[name='answ']");

    answers.on("change", function () {
        if ($("input[name='answ']:checked").length > 0) {
            pollButton.prop("disabled", false);
        } else {
            pollButton.prop("disabled", true);
        }
    });

    pollButton.on("click", function (event) {
        event.preventDefault();

        $.ajax({
            type: 'POST',
            url: BASE_URL + 'poll_vote',
            data: $('#poll_form').serialize(),
            success: function (resultData) {
                $("#poll_questions").remove();
                $("#poll_section").append(resultData);
                let poll_id = $("#poll_section").attr("poll_id");
                document.cookie = "poll_" + poll_id + "=1; path=/; expires=Fri, 31 Dec 2030 23:59:59 GMT; secure; SameSite=Strict";
                const progressBars = document.querySelectorAll(".progress-bar");

                progressBars.forEach(bar => {
                    const percent = bar.getAttribute("data-percent");

                    const fill = document.createElement("div");
                    fill.classList.add("fill");
                    fill.style.width = `${percent}%`;

                    bar.appendChild(fill);
                });
            }
        });
    });
})