// Toggle between hiding and showing the dropdown content 
function dropdownMenuOpen() {
    $('#dropdownMenuLink').toggleClass('show');    
    $('.dropdown-menu').toggleClass('open');
}

// Close the dropdown menu if the user clicks outside of it and make up arrow
window.onclick = function (e) {
    if (!e.target.matches('.dropdown-menu')) {
        var dropDown = $("#dropdownMenuLink");
        if (dropDown.hasClass('show')) {
            dropDown.removeClass('show');
            $('.dropdown-menu').removeClass('open');
        }
    }
}
