$(function ($) {

  // Tabs
  $('.tabs li').on('click', function (e) {

    if (!$(this).attr('data-tab')) {
      return;
    }

    e.preventDefault();

    let tab = $(this).attr('data-tab');
    let tabContent = $(`.tab-content[data-tab=${tab}]`);

    if (tab === 'tab-episodes' || tab === 'tab-accents') {
      $('html, body').animate({
        scrollTop: '+=1px'
      }, 10, function() {
        $('html, body').animate({ scrollTop: '-=1px' }, 0);
      });
    }

    $(this).addClass('active').siblings('li').removeClass('active');
    tabContent.addClass('active').siblings('.tab-content').removeClass('active');
  });
});