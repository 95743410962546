$(function ($) {
    const BASE_URL = window.location.origin + '/';
    var firstTime = 0;

    // Scrol to comments section
    $('.commentsBtn').on('click', function (e) {
        e.preventDefault();

        if (!$('.comments-block').hasClass('open')) {
            $('.comments-show').trigger('click');
        }

        $([document.documentElement, document.body]).animate({
            scrollTop: $("#anchorComments").offset().top - 167
        }, 500);
    });

    $('.comments-show').on('click', function () {
        var formData = {
            'id': `${article_id}`,
            'page': 1,
            'order': 1
        };

        if (firstTime === 0) {
            $.ajax({
                type: 'POST',
                url: BASE_URL + 'order_comments',
                data: formData,
                dataType: 'json',
                async: false,
                success: function (data) {
                    $('#comments_holder').html(`${data.html}`);
                    firstTime++;
                }
            }).done(function () {
                openComments();
            });
        } else {
            openComments();
        }
    });

    // Comment modal hidden with global overlay
    $('body').on('click', '.btn-comment, .answer, .report', function (e) {
        e.preventDefault();
        e.stopPropagation();

        if (!$('.comments-block').hasClass('open')) {
            $('.comments-show').trigger('click');
        }

        var commentsY = $('.comments').position().top;

        // Check if user is logged
        if (log_in === 0) {
            $('.profile').click();
            $('body').removeClass('no-scroll');

            $('body').css({ top: `-${commentsY}px`, overflowY: 'hidden' });
        } else {
            // $('body').css({ top: `${window.scrollY}px`, overflowY: 'hidden' });
            $('.global-overlay').fadeToggle();
            // Stay on the same position of modal open          
            // $('body').css('padding-right', '17px');
            $('span[id^="err-"]').remove();

            if ($(e.target).hasClass('btn-comment') || $(e.target).hasClass('answer')) {
                $('.comment-modal').addClass('show');
            }

            if (e.currentTarget.classList.contains('report')) {
                
                $('.report-modal').addClass('show');
                var id = $((this)).attr("prop_com_id");

                $("#send_report").on("click", function (e) {
                    e.preventDefault();
                    var report = $('textarea#report-comment').val();

                    $('.form[name="report_comment"] .error').remove();

                    if (report.length > 5 && report.length < 2000) {
                        var formData = {
                            'id': id,
                            'report': report
                        };

                        $.ajax({
                            type: 'POST',
                            url: BASE_URL + 'settings',
                            data: formData,
                            dataType: "json",
                            success: function (data) {
                                $('textarea#report-comment').removeClass('filled');
                                if ('msg' in data) {
                                    $('.comments-notification-error').html(data['msg']);
                                    $('.comments-notification').addClass('is-open');
                                    setTimeout(function () {
                                        $('.comments-notification').removeClass('is-open');
                                    }, 4250);
                                }
                            }

                        });

                        $('textarea#report-comment').val('');
                        $('body').removeAttr('style');
                        $('.global-overlay').fadeToggle();
                        $('.report-modal').removeClass('show');
                    } else if (report.length <= 5) {
                        errorProfileElem('Докладът е твърде кратък!', 'report_comment', 'report-comment');
                    } else if (report.length > 2000) {
                        errorProfileElem('Докладът е по-дълъг от 2000 символа!', 'report_comment', 'report-comment');
                    }

                })
            }
        }
    });

    // Stay on the same position of modal close
    $('body').on('click', '.close-modal', function (e) {
        e.preventDefault();

        closeModal();
    });

    $('body').on('click', '.modal textarea', function () {
        $('span[id^="err-"]').remove();
    })

    function closeModal() {
        const scrollY = window.scrollY;
        $('body').removeAttr('style');
        window.scrollTo(0, scrollY);
        $('.global-overlay').fadeToggle();
        $('.modal').each(function () {
            $(this).removeClass('show');
        });
        answer_to = 0;
        $('span[id$="-err"]').remove();
    }

    function openComments() {
        $('.comments-open').toggleClass('open');
        $('.comments-block').toggleClass('open');

        $('.comments-block').slideToggle("slow", function () {
            $('.comments-block').toggleClass('d-none');
        });

        $('.comments-block').slideDown('slow', function showNext() {
            $(this).next('div').slideDown('slow', showNext);
        });
    }

});