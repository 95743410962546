$(function ($) {
    const maxLength = 200,
        txt = $('.author-desc'),
        truncTxt = txt.find('p').first(),
        more = $('.desc-more');

    // Hide all paragraphes except first
    txt.find('p:not(:first-of-type)').hide();


    // Check length of first paragraph
    if ($.trim(truncTxt.text()).length > maxLength) {
        truncTxt.addClass('truncated');
        more.removeClass('d-none');
    }

    // Hide paragraphs after first
    if (txt.find('p').length > 1) {
        if (more.hasClass('d-none')) {
            more.removeClass('d-none');
        }
    }

    // Event listener for more link
    more.on('click', function () {
        more.addClass('d-none');
        truncTxt.toggleClass('truncated');
        txt.find('p:not(:first-of-type)').slideDown('slow', function showNext() {
            $(this).next('p').slideDown('slow', showNext);
        });
    });

})


