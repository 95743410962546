$(window).on('load', function () {
    if ($('.slider-links').length) {
        initSlider();
        handleScroll();
    }

});

// Initial slider
function initSlider() {
    // Check if slider has active category title
    if ($('.slider-link.active').length !== 0) {

        const active = $('.slider-link.active'),
            activePos = active.offset().left,
            length = $('.slider-link').length;

        if (!active.isInViewport()) {
            $('.slider-links').scrollLeft(activePos - active.width());

            if (window.innerWidth >= 768) {
                $('.slider-link').each(function (index) {
                    if (index <= parseInt(length - length / 2)) {
                        $(this).addClass('d-none');
                    } else {
                        if (!$(this).isInViewport()) {
                            $(this).addClass('visible');
                        }
                    }
                });

                $('.slider-link.first').removeClass('first');
                $('.slider-link.last').removeClass('last');
            }
        }
    }

    resetVisible();
    showNav();

}

// Check if link is in view port of slider
$.fn.isInViewport = function () {
    var element = $(this);
    const links = $('.slider-links'),
        sliderWidth = $('.slider'),
        navRight = $('.slider-nav.right');
    if (element.offset().left >= links.offset().left &&
        (element.offset().left + element.innerWidth()) < sliderWidth.innerWidth() + sliderWidth.offset().left - navRight.innerWidth()) {
        return true;
    }
}

function handleScroll() {

    $('.next').click(function (e) {
        e.preventDefault();

        $('.slider-link').eq(getLastIndex()).addClass('visible');
        $('.last').removeClass('last');


        $('.slider-link').eq(getFirstIndex()).removeClass('visible');
        $('.first').removeClass('first').addClass('d-none');


        resetVisible();
    });

    $('.prev').click(function (e) {
        e.preventDefault();

        $('.slider-link').eq(getFirstIndex() - 1).addClass('visible').removeClass('d-none');
        $('.first').removeClass('first');

        $('.slider-link').eq(getLastIndex()).removeClass('visible');
        $('.last').removeClass('last');

        resetVisible();
    });

    $('.slider-links').on('scroll', function (e) {
        showNav();
    });
}

function setVisible() {
    $('.slider-link').each(function () {
        if ($(this).isInViewport()) {
            $(this).addClass('visible');
        }
    })
}

function lastVisible() {
    $('.slider-link.visible').last().addClass('last');
}

function firstVisible() {
    $('.slider-link.visible').first().addClass('first');
}

function resetVisible() {
    setVisible();
    lastVisible();
    firstVisible();
    showNav();
}

function getLastIndex() {
    return $('.slider-link').index($('.last')) + 1
}

function getFirstIndex() {
    return $('.slider-link').index($('.first'));
}

function showNav() {

    const linksWr = $('.slider-links'),
        linksWrWh = linksWr.width(),
        linksWrL = linksWr.offset().left,
        links = $('.slider-link'),
        linkFL = links.first().offset().left,
        linkLL = links.last().offset().left;

    // Remove left and right arrow if neccessary
    if (linkFL >= linksWrL) {
        $('.slider-nav.left').addClass('d-none');
    }

    if (linkLL > $('.slider').width()) {
        $('.slider-nav.right').removeClass('d-none');
    }

    if (!$('.slider-link').first().isInViewport()) {
        $('.slider-nav.left').removeClass('d-none');
    } else {
        $('.slider-nav.left').addClass('d-none');
    }

    if ($('.slider-link').last().isInViewport()) {
        $('.slider-nav.right').addClass('d-none');
    }
}


