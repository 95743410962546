$(document).ready(function () {
    const BASE_URL = window.location.origin + '/';
    var is_loading = false;

    // Object validators for profile forms
    var $login = $('.form[name=login-form]'),
        validator_login = $login.validate({
            submitHandler: function (form, event) {
                var formName = form.name;
                var user = $("#login-form #login-email").val(),
                    password = $("#login-form #login-pass").val(),
                    data = getFormData(formName); // change name of form login-email=>user; login-pass=>password
                formData = { user: user, password: password };

                $.ajax({
                    type: 'POST',
                    url: BASE_URL + 'login',
                    data: formData,
                    dataType: 'json',
                    async: false,
                    success: function (data) {
                        if ('success' in data) {
                            is_loading = true;
                            $('#login-main .auth-menu-body').append('<div class="loader"></div>');
                            // Insert Profile Menu
                            $(`${data.success}`).insertAfter(".header");

                            // 
                            $('body').toggleClass('no-scroll');
                            $('.global-overlay').fadeToggle();

                            // Header Top Right Avatar
                            $('.profile').addClass('avatar-profile');

                            // Header Top Right Avatar - first letter
                            $('#first_letter').html(`${user.charAt(0)}`);

                            if ($('.comment-author').length) {
                                $('.comment-author #first_letter').html(`${user.charAt(0)}`);
                            }

                            // Header Top Right Avatar - image
                            if ('avatar' in data) {
                                var avatar_url = data.avatar + "?" + Math.random();
                                $('.header-top-right .avatar-profile img').attr('src', avatar_url).css('z-index', '2');
                                $('.header-top-right .avatar-profile img').css('display', 'block');

                                if ($('.comment-author').length) {
                                    $('.comment-author img').attr('src', avatar_url).css('z-index', '2');
                                    $('.comment-author img').css('display', 'block');
                                }
                            }

                            // Trigger click of profile menu
                            $('.profile').click();

                            // Remove login menu 
                            $("#login_menu").remove();

                            $('.delete-img').on('click', function (e) {
                                e.preventDefault();

                                $('.form[name=photo_change]').submit();
                            });

                            $('.form :input').each(function () {
                                $(this).closest('.input-wrapper').find('span[id^="err-"]').remove();
                                checkFilled($(this));
                            });

                             // Login/Profile hidden menu with global overlay
                            $(' .close-auth').on('click', function (e) {
                                e.preventDefault();

                                $('body').toggleClass('no-scroll');
                                $('.global-overlay').fadeToggle();
                                $('.auth-menu').toggleClass('auth-menu-open');
                                $('.auth-menu.auth-submenu-open').each(function () {
                                $(this).removeClass('auth-submenu-open');
                                });
                            });

                            $('#login_menu .close-auth').on('click', function (e) {
                                e.preventDefault();

                                $('body').removeClass('no-scroll');
                                $('.global-overlay').fadeOut();
                                $('.hamburger-menu').removeClass('is-open');
                                $('.auth-menu').removeClass('auth-menu-open');
                                $('.modal').each(function () {
                                $(this).removeClass('show');
                                $('textarea').removeClass('filled');
                                });
                                $('body').removeAttr('style');
                            });

                            // Comment modal
                            log_in = 1;
                            if ($('.comment-author-name').length) {
                                $('.comment-author-name').empty();
                                $('.comment-author-name').html(`${user}`);
                            }

                            $('.form').each(function () {
                                $(this).validate({
                                    submitHandler: function (form, event) {
                                        var formName = form.name;

                                        switch (formName) {
                                            case 'photo_change':
                                                var file = $('#upload_your_image').prop('files')[0],
                                                    formData = new FormData();
                                                formData.append('picture', file);

                                                if (typeof file != 'undefined' && file) {
                                                    $('#profile-photo-change .auth-menu-body').append('<div class="loader"></div>');

                                                    $.ajax({
                                                        url: BASE_URL + 'settings',
                                                        type: 'POST',
                                                        data: formData,
                                                        success: function (data) {
                                                            $('#profile-photo-change .auth-menu-body .loader').remove();
                                                            $('#upload_your_image').val(null);

                                                            if (data[0] === 'success') {
                                                                var avatarUrl = `${data[1]}?` + Math.random();
                                                                successMessage('photo-change');
                                                                $('.delete-img').css('display', 'flex');
                                                                $('label[for=upload_your_image').css('display', 'none');
                                                                $('#profile-photo-change .circle-img-inner, #profile-main .circle-img-inner').css('display', 'none');
                                                                $('#profile_menu .avatar-profile img,.profile.avatar-profile img ').each(function () {
                                                                    $(this).attr('src', avatarUrl);
                                                                    $(this).css('display', 'block');
                                                                });
                                                                $('.header-top-right .avatar-profile img').css('z-index', '2');
                                                            }

                                                            if (data[0] === 'error') {
                                                                warningMessage('photo_change', `${data[1]}`)
                                                            }

                                                        },
                                                        dataType: 'json',
                                                        cache: 'no-cache',
                                                        contentType: false,
                                                        processData: false
                                                    })
                                                } else {
                                                    $.ajax({
                                                        url: BASE_URL + 'settings',
                                                        type: 'POST',
                                                        data: { 'delete_img': 'on' },
                                                        success: function (data) {
                                                            $('.delete-img').css('display', 'none');
                                                            $('label[for=upload_your_image]').css('display', 'block');
                                                            $('#upload_your_image').removeAttr('disabled');
                                                            $('#profile_menu .avatar-profile img,.profile.avatar-profile img ').each(function () {
                                                                $(this).attr('src', `data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg' %3E%3C/svg%3E`);
                                                                $(this).css('display', 'none');
                                                            });
                                                            $('#profile-photo-change .circle-img-inner, #profile-main .circle-img-inner, .avatar-profile .circle-img-inner').css('display', 'block');

                                                        }
                                                    })

                                                }

                                                break;
                                            case 'about':
                                                var formData = getFormData(form.name);
                                                successMessageEmpty(form.name);

                                                processProfileForm(BASE_URL + 'settings', formData, function (data) {

                                                    if (data[0] === 'success') {
                                                        $('.form[name=about]').trigger('reset');
                                                        successMessage(form.name);
                                                        $('#real_name').html(formData['real-name']);
                                                        $('#real-name').val(formData['real-name']);
                                                    }
                                                })

                                                break;
                                            case 'notification':
                                                var notification = $('input[name="send_notification"]').is(':checked'),
                                                    formData = { send_notification: notification };

                                                successMessageEmpty(form.name);

                                                $.ajax({
                                                    type: 'POST',
                                                    url: BASE_URL + 'settings',
                                                    data: formData,
                                                    dataType: 'json',
                                                    async: false,
                                                    success: function (data) {
                                                        $('#profile-email .error').remove();

                                                        if (data[0] === 'success') {
                                                            successMessage(form.name);
                                                        }
                                                    }
                                                });
                                                break;
                                            case 'change_mail':
                                                var formData = getFormData(form.name),
                                                    errorMap = { 'curr-password': 'Грешна парола!', 'new-email': 'Този емайл адрес вече е регистриран!' };;

                                                successMessageEmpty('email');

                                                processProfileForm(BASE_URL + 'settings', formData, function (data) {
                                                    $('#profile-email .error').remove();

                                                    if ('error' in data) {
                                                        $.each(errorMap, function (elem, elemError) {
                                                            errorProfileElem(elemError, formName, elem);
                                                        })
                                                    }

                                                    if (data[0] === 'success') {
                                                        $('#profile-email-message').addClass('auth-submenu-open');
                                                        $('#profile-email-message .email-change').html(formData['new-email']);
                                                        $('#profile-email').removeClass('auth-submenu-open');
                                                        $('#profile-email').val('');
                                                        $('#profile-email').removeClass('filled valid');
                                                        $('.form[name=change_mail]')[0].reset();
                                                    }
                                                })
                                                break;
                                            case 'password':
                                                var formData = getFormData(form.name),
                                                    errorMap = { 'curr-password': 'Грешна парола!', 'new-password': 'Въведените пароли не съвпадат!' };

                                                successMessageEmpty(form.name);
                                                processProfileForm(BASE_URL + 'settings', formData, function (data) {
                                                    $('#profile-password .error').remove();

                                                    if ('error_password' in data) {
                                                        errorProfileElem(errorMap['curr-password'], formName, 'curr-password');
                                                    }

                                                    if ('error_bothpassword' in data) {
                                                        errorProfileElem(errorMap['new-password'], formName, 'new-password');
                                                    }

                                                    if (data[0] === 'success') {
                                                        $('.form[name=password]')[0].reset();
                                                        successMessage(form.name);
                                                        $('#profile-menu-mail').html(formData['new-email']);
                                                    }
                                                })

                                                break;
                                            case 'delete_profile':
                                                var formName = form.name,
                                                    formData = getFormData(formName),
                                                    errorMap = { 'curr-password': 'Грешна парола!' };

                                                successMessageEmpty(formName);
                                                processProfileForm(BASE_URL + 'settings', formData, function (data) {
                                                    $('#profile-delete .error').remove();

                                                    if ('error' in data) {
                                                        $.each(errorMap, function (elem, elemError) {
                                                            errorProfileElem(elemError, formName, elem);
                                                        })
                                                    }

                                                    if (data[0] === 'success') {
                                                        $('.form[name=delete_profile]')[0].reset();
                                                        successMessage(formName);
                                                    }
                                                })
                                                break;

                                        }
                                    }
                                })
                            });

                            is_loading = false;

                        }

                        if ('error' in data) {
                            $('#login-form .error').remove();
                            errorProfileElem(data.error, 'login-form', 'login-email');
                        }
                    }
                });
            }
        }),
        $register = $('.form[name=register-form]'),
        validator_register = $register.validate({
            submitHandler: function (form, event) {
                if (grecaptcha.getResponse().length == 0) {
                    $('#recaptcha-error').show();
                } else {
                    form.off('submit').submit();
                }
            }
        }),
        $recover_password_from = $('.form[name=recover_password_from]'),
        validator_recover_password_from = $recover_password_from.validate({
            submitHandler: function (form, event) {
                $(this).off('submit').submit();
                return true;
            }
        }),
        $about = $('.form[name=about]'),
        validator_about = $about.validate({
            submitHandler: function (form, event) {
                var formName = form.name,
                    formData = getFormData(formName);

                successMessageEmpty(formName);
                $('#profile-about .success-message').empty();
                processProfileForm(BASE_URL + 'settings', formData, function (data) {

                    if (data[0] === 'success') {
                        $('.form[name=about]').trigger('reset');
                        successMessage(formName);
                        $('#real_name').html(formData['real-name']);
                        $('#real-name').val(formData['real-name']);
                    }
                })
            }
        }),
        $form_notification = $('.form[name=notification]'),
        validator_notification = $form_notification.validate({
            submitHandler: function (form, event) {
                var notification = $('input[name="send_notification"]').is(':checked'),
                    formData = { send_notification: notification };

                successMessageEmpty(form.name);
                processProfileForm(BASE_URL + 'settings', formData, function (data) {
                    $('#profile-email .error').remove();

                    if (data[0] === 'success') {
                        successMessage(form.name);
                    }
                });
            }
        }),
        $change_mail = $('.form[name=change_mail]'),
        validator_change_mail = $change_mail.validate({
            submitHandler: function (form, event) {
                var formName = form.name,
                    formData = getFormData(formName),
                    errorMap = { 'curr-password': 'Грешна парола', 'new-email': 'Този e-mail адрес е вече регистриран' };

                successMessageEmpty('email');
                processProfileForm(BASE_URL + 'settings', formData, function (data) {
                    $('#profile-email .error').remove();

                    if ('error' in data) {
                        $.each(errorMap, function (elem, elemError) {
                            errorProfileElem(elemError, formName, elem);
                        })
                    }

                    if (data[0] === 'success') {
                        $('#profile-email-message').addClass('auth-submenu-open');
                        $('#profile-email-message .email-change').html(formData['new-email']);
                        $('#profile-email').removeClass('auth-submenu-open');
                        $('#profile-email').val('');
                        $('#profile-email').removeClass('filled valid');
                        $('.form[name=change_mail]')[0].reset();
                    }
                })
            }
        }),
        $password = $('.form[name=password]'),
        validator_password = $password.validate({
            submitHandler: function (form, event) {
                var formName = form.name,
                    formData = getFormData(formName),
                    errorMap = { 'curr-password': 'Грешна парола!', 'new-password': 'Въведените пароли не съвпадат!' };

                successMessageEmpty(formName);
                processProfileForm(BASE_URL + 'settings', formData, function (data) {
                    $('#profile-password .error').remove();

                    if ('error_password' in data) {
                        errorProfileElem(errorMap['curr-password'], formName, 'curr-password');
                    }

                    if ('error_bothpassword' in data) {
                        errorProfileElem(errorMap['new-password'], formName, 'new-password');
                    }

                    if (data[0] === 'success') {
                        $('.form[name=password]')[0].reset();
                        successMessage(formName);
                        $('#profile-menu-mail').html(formData['new-email']);
                        $('#profile-password .filled').removeClass('filled');
                    }
                })
            }
        }),
        $change_photo = $('.form[name=photo_change]'),
        validator_change_photo = $change_photo.validate({
            submitHandler: function (form, event) {
                var file = $('#upload_your_image').prop('files')[0],
                    formData = new FormData();
                formData.append('picture', file);

                if (typeof file != 'undefined' && file) {
                    $('#profile-photo-change .auth-menu-body').append('<div class="loader"></div>');

                    $.ajax({
                        url: BASE_URL + 'settings',
                        type: 'POST',
                        data: formData,
                        success: function (data) {
                            $('#profile-photo-change .auth-menu-body .loader').remove();

                            $('#upload_your_image').val(null);

                            if (data[0] === 'success') {
                                var avatarUrl = `${data[1]}?` + Math.random();
                                successMessage('photo-change');
                                $('.delete-img').css('display', 'flex');
                                $('label[for=upload_your_image]').css('display', 'none');
                                $('#profile-photo-change .circle-img-inner, #profile-main .circle-img-inner').css('display', 'none');
                                $('#profile_menu .avatar-profile img,.profile.avatar-profile img ').each(function () {
                                    $(this).attr('src', avatarUrl);
                                    $(this).css('display', 'block');
                                });
                                $('.header-top-right .avatar-profile img').css('z-index', '2');
                            }

                            if (data[0] === 'error') {
                                warningMessage('photo_change', `${data[1]}`)
                            }

                        },
                        dataType: 'json',
                        cache: 'no-cache',
                        contentType: false,
                        processData: false
                    })
                } else {
                    $.ajax({
                        url: BASE_URL + 'settings',
                        type: 'POST',
                        data: { 'delete_img': 'on' },
                        success: function (data) {
                            $('.delete-img').css('display', 'none');
                            $('label[for=upload_your_image]').css('display', 'block');
                            $('#upload_your_image').removeAttr('disabled');
                            $('#profile_menu .avatar-profile img,.profile.avatar-profile img ').each(function () {
                                $(this).attr('src', `data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg' %3E%3C/svg%3E`);
                                $(this).css('display', 'none');
                            });
                            $('#profile-photo-change .circle-img-inner, #profile-main .circle-img-inner, .avatar-profile .circle-img-inner').css('display', 'block');

                        }
                    })

                }
            }
        }),
        $recover_password = $('.form[name=recover_password]'),
        validator_recover_password = $recover_password.validate({
            submitHandler: function (form, event) {
                var formName = form.name,
                    formData = getFormData(formName);

                processProfileForm(BASE_URL + 'recover_password', formData, function (data) { });

                $('#forgotten-pass-message').addClass('auth-submenu-open');
                $('#forgotten-pass-message .email-forgotten-pass').html(formData.recover_password);
                $('#forgotten-pass').removeClass('auth-submenu-open');
                $('#forgotten-pass-email').val('');
                $('#forgotten-pass-email').removeClass('filled valid');

            }
        }),
        $delete_profile = $('.form[name=delete_profile]'),
        validator_delete_profile = $delete_profile.validate({
            submitHandler: function (form, event) {
                var formName = form.name,
                    formData = getFormData(formName),
                    errorMap = { 'curr-password': 'Грешна парола!' };

                successMessageEmpty(formName);
                processProfileForm(BASE_URL + 'settings', formData, function (data) {
                    $('#profile-delete .error').remove();

                    if ('error' in data) {
                        $.each(errorMap, function (elem, elemError) {
                            errorProfileElem(elemError, formName, elem);
                        })
                    }

                    if (data[0] === 'success') {
                        $('.form[name=delete_profile]')[0].reset();
                        successMessage(formName);
                    }
                })
            }
        });

    // Add method with regex for check username
    $.validator.addMethod('regex', function (value) {
        return /^(?!.*[_.]$)(?=.{3,20}$)[^ ][\w\W ]*[^ ](?!.*[_.]{2})(?!.*[§@#€£$^{}~<>'"\/;`%])[a-zA-Z0-9-]/.test(value);
    }, 'Потребителското име е невалидно');

    // Add method with regex for check username
    $.validator.addMethod('valid-email', function (value) {
        return /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value);
    }, 'Моля въведете валиден e-mail адрес');

    // Add method with regex for check password
    $.validator.addMethod('pass-space', function (value) {
        return /^[^\s](.*[^\s])?$/.test(value);
    }, 'Паролата не трябва да започва или завърша с интервал');

    $.validator.addMethod('image-upload', function (value, element) {
        return this.optional(element) || /([a-zа-яА-ЯA-Z0-9\s_\\.\-:])+(.png|.jpg|.jpeg|.gif|.JPG|.JPEG|.PNG|.GIF)$/.test(value);
    }, 'Моля изберете снимка с разширение jpg, jpeg, png, gif');

    $.validator.addMethod('filesize', function (value, element) {
        return this.optional(element) || (element.files[0].size <= 2 * 1000000)
    }, 'Снимката трябва да бъде с максимално големина 2 MB');

    // Set defaults validator
    $.validator.setDefaults({
        highlight: function (e) {
            $(e).closest('.form-group').addClass('invalid');
        },
        unhighlight: function (e) {
            $(e).closest('.form-group').removeClass('invalid');
        }
    })

    // Check form input valid on change, autofill
    $('.form :input').each(function () {
        $(this).closest('.input-wrapper').find('span[id^="err-"]').remove();
        checkFilled($(this));
    });

    $('.form input:-webkit-autofill').each(function () {
        $(this).closest('.input-wrapper').find('span[id^="err-"]').remove();
        $(this).addClass('filled');
    });

    $('body').on('blur change', '.form :input', function () {
        $(this).closest('.input-wrapper').find('span[id^="err-"]').remove();
        checkFilled($(this));
        $('.success-message').html('');
    });

    // Show / Hide password 
    $('.see-password').on('click', function (e) {
        e.preventDefault();
        if ($(this).siblings('input').attr('type') === 'password') {
            $(this).siblings('input').attr('type', 'text');
            $(this).addClass('open');
        } else {
            $(this).siblings('input').attr('type', 'password');
            $(this).removeClass('open');
        }

    });

    // Open submenu in main login/reg/profile
    $('body').on('click', '.btn-open-submenu', function () {
        var submenu = $(this).attr('data-submenu');

        $(submenu).addClass('auth-submenu-open');
    });

    // Back submenu
    $('body').on('click', '.menu-back', function () {
        var parent = $(this).closest('aside.auth-submenu'),
            parentId = parent[0].id,
            prev = parent.prev();

        // if ($('.form[name=login-form]').length && parent[0].id === 'forgotten-pass-message') {
        //     var validator_login = $('.form[name=login-form]').validate();
        //     validator_login.resetForm();
        //     if (prev.find('.form').length) {
        //         var validator_prev = prev.find('.form').validate();
        //         validator_prev.resetForm();
        //         prev.find('.form .error').remove();
        //     }
        // };

        switch (parentId) {
            case 'profile-password':
                $('.form[name=password]')[0].reset();
                removeLabels();
                $('.form[name=password] .filled').removeClass('filled');
                break;
            case 'profile-email':
                $('.form[name=change_mail]')[0].reset();
                removeLabels();
                break;
            case 'forgotten-pass':
                $('.form[name=recover_password]')[0].reset();
                removeLabels();
                break;
            case 'forgotten-pass-message':
                var validator_login = $('.form[name=login-form]').validate();
                validator_login.resetForm();
                removeLabels();
                break;
        }

        if (parent.find('.form').length) {
            var validator_curr = parent.find('.form').validate();
            validator_curr.resetForm();
        }

        parent.find('.success-message').html('');

        parent.addClass('auth-submenu-close');
        parent.delay(500).queue(function (next) {
            $(this).removeClass("auth-submenu-open");
            $(this).removeClass("auth-submenu-close");
            next();
        });

    });

    // Event Listener for upload new avatar image

    $('body').on('change', '#upload_your_image', function () {
        if ($('#upload_your_image').length > 0) {
            if (this.files && this.files[0]) {
                $('.form[name=photo_change]').validate();
                if ($('.form[name=photo_change]').valid()) {
                    var FR = new FileReader();

                    FR.addEventListener("load", function (e) {
                        $('.delete-warning').css('display', 'none');
                        $('.delete-img-warning').html('');
                    });
                    FR.readAsDataURL(this.files[0]);

                    // successMessageEmpty('photo-change');
                    $('#profile-photo-change .auth-menu-body .loader').remove();

                    $('.form[name=photo_change]').submit();
                } else {
                    $('#upload_your_image').val(null);
                }
            }
        }
    });

    $('body').on('click', '.delete-img', function (e) {
        e.preventDefault();

        $('.form[name=photo_change]').submit();

    })


});

function checkFilled(el) {
    if (el.val() && el.attr('type') !== 'hidden') {
        el.addClass('filled');
    } else {
        el.closest('div.input-wrapper').find('span[id^="err-"]').remove();
        el.removeClass('filled');
    }
}

function recaptchaCallback(е) {
    е.preventDefault();
}

function getFormData(form) {
    var data = $(`.form[name=${form}]`).serializeArray().reduce(function (obj, item) {
        obj[item.name] = item.value;
        return obj;
    }, {});
    return data;
}

function processProfileForm(url, inputData, callback) {
    $.post(url, inputData, function (data) {
        if (callback != null) {
            callback(data);
        }
    }, 'json');
}

function errorProfileElem(error, wrapper, el) {
    var errorWrap = $(`.form[name="${wrapper}"] #${el}`).parent();
    errorsHtml = `<span id="err-${el}" class="error">${error}</span>`;
    errorWrap.append(errorsHtml);
}

function removeLabels() {

    $(`label[id$='-error']`).remove();
    $(`span[id$='-error']`).remove();

}

function successMessage(formName) {
    $(`#profile-${formName} .success-message`).html('Промените са направени успешно!');
    setTimeout(function () {
        $(`#profile-${formName} .success-message`).html('');
    }, 3000);
}

function successMessageEmpty(formName) {
    $(`#profile-${formName} .success-message`).html('');
}

function warningMessage(formName, message) {
    $(`#profile-${formName} .warning-message`).html(`${message}`);
    setTimeout(function () {
        $(`#profile-${formName} .warning-message`).html('');
    }, 4000);
}

