document.addEventListener("DOMContentLoaded", function (event) {
    var t = 1;
    $("#sticker-sticky-wrapper").removeAttr("style"),
        function (t) {
            "function" == typeof define && define.amd ? define(["jquery"], t) : "object" == typeof module && module.exports ? module.exports = t(require("jquery")) : t(jQuery)
        }(function (e) {
            var i = Array.prototype.slice,
                n = Array.prototype.splice,
                s = {
                    topSpacing: 0,
                    bottomSpacing: 0,
                    className: "is-sticky",
                    wrapperClassName: "sticky-wrapper",
                    center: !1,
                    getWidthFrom: "",
                    widthFromWrapper: !0,
                    responsiveWidth: !1,
                    zIndex: "inherit"
                },
                r = e(window),
                o = e(document),
                c = [],
                a = r.height(),
                // articleWidth = $("#sticker-sticky-wrapper").width() - 320;
                articleWidth = 0;
            p = (e(".sticky-main").width(), e(".sticky-sidebar").width(), function () {
                for (var i = r.scrollTop(), n = o.height(), s = n - a, p = i > s ? s - i : 0, d = 0, l = c.length; l > d; d++) {
                    var h = c[d],
                        u = h.stickyWrapper.offset().top,
                        m = u - h.topSpacing - p;
                    if (h.stickyWrapper.css("height", "476px"), m >= i) null !== h.currentTop && (h.stickyElement.css({
                        width: "",
                        position: "",
                        top: "",
                        "z-index": ""
                    }), h.stickyElement.parent().removeClass(h.className), h.stickyElement.trigger("sticky-end", [h]), h.currentTop = null), h.stickyElement.show(), h.stickyElement.find("iframe").css("width", "100%"), h.stickyElement.find("iframe").css("height", "100%"), h.stickyElement.css("top", "").css("bottom", "").css("z-index", h.zIndex).css("margin-top", ""), e(".closeBtn").hide(), e("#sticker").removeAttr("style");
                    else if ("undefined" == typeof e.cookie("close_cookie_" + articleId) && $(window).width() >= 1024) {
                        var g = n - h.stickyElement.outerHeight() - h.topSpacing - h.bottomSpacing - i - p;
                        if (0 > g ? g += h.topSpacing : g = h.topSpacing, h.currentTop !== g) {
                            if (t) {
                                //_gaq.push(["_trackEvent", "Minimize video", "minimized"]);
                                t = 0
                            }
                            h.getWidthFrom ? (padding = h.stickyElement.innerWidth() - h.stickyElement.width(), y = e(h.getWidthFrom).width() - padding || null) : h.widthFromWrapper && (y = h.stickyWrapper.width()), null == y && (y = h.stickyElement.width()), h.stickyElement.css("width", y).css("position", "fixed").css("top", g).css("z-index", h.zIndex), h.stickyElement.parent().addClass(h.className), null === h.currentTop ? h.stickyElement.trigger("sticky-start", [h]) : h.stickyElement.trigger("sticky-update", [h]), h.currentTop === h.topSpacing && h.currentTop > g || null === h.currentTop && g < h.topSpacing ? h.stickyElement.trigger("sticky-bottom-reached", [h]) : null !== h.currentTop && g === h.topSpacing && h.currentTop < g && h.stickyElement.trigger("sticky-bottom-unreached", [h]), h.currentTop = g
                        }
                        var f = h.stickyWrapper.parent(),
                            k = h.stickyElement.offset().top + h.stickyElement.outerHeight() >= f.offset().top + f.outerHeight() && h.stickyElement.offset().top <= h.topSpacing;
                        if (k) h.stickyElement.css("position", "absolute").css("top", "").css("bottom", 0).css("z-index", "");
                        else {
                            var w = a - h.topSpacing - 181;
                            h.stickyElement.width(), h.stickyElement.css("position", "fixed").css("top", g).css("bottom", "").css("z-index", h.zIndex).css("margin-top", w + "px").css("margin-left", articleWidth + "px").css("right", "0").css("width", "320px"), h.stickyElement.find("iframe").css("width", "320px"), h.stickyElement.find("iframe").css("height", "181px"), h.stickyElement.css("z-index", "9999"), e(".closeBtn").show();
                        }
                    }
                }
            }),
                d = function () {
                    a = r.height();
                    for (var t = 0, i = c.length; i > t; t++) {
                        var n = c[t],
                            s = null;
                        n.getWidthFrom ? n.responsiveWidth && (s = e(n.getWidthFrom).width()) : n.widthFromWrapper && (s = n.stickyWrapper.width()), null != s && n.stickyElement.css("width", s)
                    }
                },
                l = {
                    init: function (t) {
                        return this.each(function () {
                            var i = e.extend({}, s, t),
                                n = e(this),
                                r = n.attr("id"),
                                o = r ? r + "-" + s.wrapperClassName : s.wrapperClassName;
                            e("<div></div>").attr("id", o).addClass(i.wrapperClassName), n.wrapAll(function () {
                                return 0 == e(this).parent("#" + o).length
                            });
                            var a = n.parent();
                            i.center && a.css({
                                width: n.outerWidth(),
                                marginLeft: "auto",
                                marginRight: "auto"
                            }), "right" === n.css("float") && n.css({
                                "float": "none"
                            }).parent().css({
                                "float": "left"
                            }), i.stickyElement = n, i.stickyWrapper = a, i.currentTop = null, c.push(i), l.setWrapperHeight(this), l.setupChangeListeners(this)
                        })
                    },
                    setWrapperHeight: function (t) {
                        var i = e(t),
                            n = i.parent();
                        n && n.css("height", i.outerHeight())
                    },
                    setupChangeListeners: function (t) {
                        if (window.MutationObserver) {
                            var e = new window.MutationObserver(function (e) {
                                (e[0].addedNodes.length || e[0].removedNodes.length) && l.setWrapperHeight(t)
                            });
                            e.observe(t, {
                                subtree: !0,
                                childList: !0
                            })
                        } else window.addEventListener ? (t.addEventListener("DOMNodeInserted", function () {
                            l.setWrapperHeight(t)
                        }, !1), t.addEventListener("DOMNodeRemoved", function () {
                            l.setWrapperHeight(t)
                        }, !1)) : window.attachEvent && (t.attachEvent("onDOMNodeInserted", function () {
                            l.setWrapperHeight(t)
                        }), t.attachEvent("onDOMNodeRemoved", function () {
                            l.setWrapperHeight(t)
                        }))
                    },
                    update: p,
                    unstick: function (t) {
                        return this.each(function () {
                            for (var t = this, i = e(t), s = -1, r = c.length; r-- > 0;) c[r].stickyElement.get(0) === t && (n.call(c, r, 1), s = r); - 1 !== s && (i.unwrap(), i.css({
                                width: "",
                                position: "",
                                top: "",
                                "float": "",
                                "z-index": ""
                            }))
                        })
                    }
                };
            window.addEventListener ? (window.addEventListener("scroll", p, !1), window.addEventListener("resize", d, !1)) : window.attachEvent && (window.attachEvent("onscroll", p), window.attachEvent("onresize", d)), e.fn.sticky = function (t) {
                return l[t] ? l[t].apply(this, i.call(arguments, 1)) : "object" != typeof t && t ? void e.error("Method " + t + " does not exist on jQuery.sticky") : l.init.apply(this, arguments)
            }, e.fn.unstick = function (t) {
                return l[t] ? l[t].apply(this, i.call(arguments, 1)) : "object" != typeof t && t ? void e.error("Method " + t + " does not exist on jQuery.sticky") : l.unstick.apply(this, arguments)
            }, e(function () {
                setTimeout(p, 0)
            })
        }), $(".closeBtn").click(function () {
            var t = new Date;
            t.setTime(t.getTime() + 36e5), $.cookie("close_cookie_" + articleId, articleId, {
                expires: t
            }), $("#sticker").hide();
        }), $("#sticker").sticky({
            topSpacing: -500,
            className: "leftFloat"
        });
});