function showNative(obj) {
    let blockForReplace,
        afterParagraph,
        blockHtml;
        if ($('.home .news-join .nativeAd').length > 0) {
            blockHtml = `
            <div class="thumb-horizontal">
            <a href="${obj.url}" class="text-wrapper img-wrapper object-fit" target="_blank">
                <img src="${obj.thumbSquared}" -fit" target="_blank">
                    <img src="${obj.thumbSquared}" width="116" height="90" alt="${decodeURIComponent(obj.title)}">
            </a>        
                <a class="text-wrapper-aside" href="${obj.url}" target="_blank">
                    <h2 class="primary-title"> ${decodeURIComponent(obj.title)}
                    </h2>                    
                </a>                
                </div>
                <a class="native-source" href="${obj.url}" >${obj.hostSite}</a>`
        } else if ($('.article-text .nativeAd').length > 0) {
            blockHtml = `
            <h3 class="native-text" >
                <a class="native-title" href="${obj.url}" target="_blank">
                    ${decodeURIComponent(obj.title)}
                </a>
                <a class="native-source" href="${obj.url}" >${obj.hostSite}</a>
            </h3>
                <a href="${obj.url}" class="text-wrapper img-wrapper object-fit" target="_blank">
                    <img src="${obj.thumbSquared}" width="113" height="116" alt="${decodeURIComponent(obj.title)}">
                </a>`
        } else if ($('.masonry-grid.mGrid .nativeAd').length > 0)  {
            blockHtml= `
            <a class="native-source" href="${obj.url}" target="_blank">${obj.hostSite}</a>
            <h2 class="second-title">
            <a href="${obj.url}" target="_blank">${decodeURIComponent(obj.title)}</a>
        </h2>
        <a href="${obj.url}" target="_blank">
            <figure class="text-wrapper">
                <div class="img-wrapper object-fit">
                  <img src="${obj.thumbSquared}" width="345" height="272" alt="${decodeURIComponent(obj.title)}"></div>
            </figure>
        </a>`
        }     
    
    $('.nativeAd').removeClass('banner').empty().html(blockHtml)

}