$(function ($) {

  // Hamburger hidden menu with global overlay
  $('.hamburger-btn, .close-menu').on('click', function (e) {
    e.preventDefault();

    $('body').toggleClass('no-scroll');
    $('.global-overlay').fadeToggle();
    $('.hamburger-menu').toggleClass('is-open');
  });

  // Login/Profile hidden menu with global overlay
  $('.profile, .close-auth').on('click', function (e) {
    e.preventDefault();

    $('body').toggleClass('no-scroll');
    $('.global-overlay').fadeToggle();
    $('.auth-menu').toggleClass('auth-menu-open');
    $('.auth-menu.auth-submenu-open').each(function () {
      $(this).removeClass('auth-submenu-open');
    });
  });

  $('.global-overlay, #login_menu .close-auth').on('click', function (e) {
    e.preventDefault();

    $('body').removeClass('no-scroll');
    $('.global-overlay').fadeOut();
    $('.hamburger-menu').removeClass('is-open');
    $('.auth-menu').removeClass('auth-menu-open');
    $('.modal').each(function () {
      $(this).removeClass('show');
      $('textarea').removeClass('filled');
    });
    $('body').removeAttr('style');
    answer_to = 0;
  });

  // GDPR
  $('.trigger-faktorio').on('click', function (e) {
    e.preventDefault();
    $('#fingerprint').trigger('click');
  });

  $('.trigger-onesignal').on('click', function (e) {
    e.preventDefault();
    $('.onesignal-bell-launcher-button').toggleClass('visible');
    $('.onesignal-bell-launcher-button').trigger('click');
  });

  // Lazy load
  let lazyLoad = $('.lazy').Lazy({
    chainable: false,
    effect: 'fadeIn',
    effectTime: 500,
    visibleOnly: true,
    afterLoad: function (element) {
      element.removeClass('lazy');
      masonryEffect(".mGrid")
    }
  });

  $(document).ajaxSuccess(function (event, xhr, settings) {

    $('.lazy').Lazy({
      chainable: false,
      effect: 'fadeIn',
      effectTime: 500,
      visibleOnly: true,
      afterLoad: function (element) {
        element.removeClass('lazy');
        masonryEffect(".mGrid")
      }
    });

  });

  $('.show_signal').click(function () {
    OneSignal.push(function () {
      OneSignal.showNativePrompt();
    })
  });



});