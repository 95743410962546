$(function($){

// Open Search bar
$('.search').on('click', function(e){
    e.preventDefault();

    $('body').toggleClass('no-scroll');
    $('.search-bar').toggleClass('is-open');
})

// Close Search bar
$('.search-close').on('click', function(e){
    e.preventDefault();

    $('body').removeClass('no-scroll');
    $('.search-bar').removeClass('is-open');
})

});