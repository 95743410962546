/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

$(window).on('load resize', function () {
  // setInterval(function() {
    masonryEffect(".mGrid");
  // }, 100);
});

// masonry effect
function masonryEffect(cName) {
  $(cName).each(function (i, obj) {
    var h = 0;
    var h1 = 0;
    var h2 = 0;
    var childs = $(obj).find(".wrap-articles_item");
    childs.each(function (j) {
      j % 2 === 0 ? h1 += Math.ceil($(this).outerHeight(true)) : h2 += Math.ceil($(this).outerHeight(true));
    });

    h = Math.max(h1, h2);
    if ($(window).width() >= 768) {
      $(obj).css("height", h + 10 + 'px');
    }
  });
}

